<template>

    <OModal name="arenaBatchDialog" ref="arenaBatchDialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ props.modalTitle ? props.modalTitle : $t('Batch Update') + ' - ' + $t('Published To') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div class="modal-body">
                    <div class="d-flex">
                        <!-- <span style='font-weight:bold' class='text-muted'>{{ $t('Will update') }} <span class='text-danger'>{{ props.dataObject.data.filter(row => row.isSelected).length }}</span> {{ $t('records') }}</span> -->
                        <!-- <div class="form-check-inline" v-if="batchIsPublishOrgUnits"> -->
                            <!-- <div class="form-check form-check-inline"> -->
                                <!-- <input class="form-check-input" type="checkbox" id="doNotInherit" v-model="doNotInherit"> -->
                                <!-- <label class="form-check-label" for="doNotInherit">{{$t('Do Not Inherit')}}</label> -->
                            <!-- </div> -->
                        <!-- </div> -->

                        <label v-if="props.customOptions" class="me-2">{{ $t('Set on all rows')+':' }}</label>
                        <slot></slot>

                        <div class="form-check-inline ms-auto" v-if="!props.insertOnly">
                            <div class="form-check form-check-inline" :title="$t('Insert, keep existing')">
                                <input class="form-check-input" name="updateOptions" type="radio" id="insert" value="Insert" v-model="updateOptions">
                                <label class="form-check-label" for="insert">{{$t('Insert')}}</label>
                            </div>
                            <div class="form-check form-check-inline" :title="$t('Insert and replace existing')">
                                <input class="form-check-input" name="updateOptions" type="radio" id="overwrite" value="Overwrite" v-model="updateOptions">
                                <label class="form-check-label" for="overwrite">{{$t('Overwrite')}}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" name="updateOptions" type="radio" id="remove" value="Remove" v-model="updateOptions">
                                <label class="form-check-label" for="remove">{{$t('Remove')}}</label>
                            </div>
                            <small v-if="updateOptions == 'Remove'">
                                {{$t('Replace with')+':'}}
                                <OOrgUnitsLookup :bind="sel => { replaceName = sel.Name; replace_ID = sel.ID }" blockedContent="atbl_Arena_Documents" v-if="recordType == 'OrgUnit'">
                                    <template #orgunit>
                                        <a href="#">
                                            <span v-if="!replace_ID">{{$t('None')}}</span>
                                            <span v-else>{{ replaceName }}</span>
                                        </a>
                                    </template>
                                </OOrgUnitsLookup>
                            </small>
                        </div>
                    </div>
                    <ODataGrid 
                        :data-object="dsBatchSetup"
                        hideGridMenu
                        hideMultiselectColumn 
                        newRecordsPosition="bottom"
                        style="min-height:300px;"
                        ref="batchSetupRef"
                        disableFilterRow
                    >

                        <OColumn colId="OrgUnit" width="400" editable :headerName="$t('Org Unit')"  v-if="props.recordType == 'OrgUnit'">
                            <template #default="{row}">
                                {{ row.JsonField.OrgUnit }}
                            </template>
                            <template #editor="{row}">
                                <OOrgUnitsLookup :bind="sel=>{row.General_ID  = sel.ID; row.JsonField.OrgUnit = sel.OrgUnit; row.JsonField.UnitType = sel.UnitType;}">
                                    <template #orgunit>
                                        <input type="text" :value="row.JsonField.OrgUnit">
                                    </template>
                                </OOrgUnitsLookup> 
                            </template>

                        </OColumn>
                        <OColumn colId="UnitType" width="200" editable :headerName="$t('Unit Type')" v-if="props.recordType == 'OrgUnit'">
                            <template #default="{row}">
                                {{ row.JsonField.UnitType }}
                            </template>
                        </OColumn>

                        <OColumn colId="DoNotInherit" width="200" editable :headerName="$t('Do Not Inherit')" v-if="props.recordType == 'OrgUnit' && !props.doNotShowDoNotInherit">
                            <template #default="{row}">
                                <OBitEditor v-model="row.JsonField.DoNotInherit"/>
                            </template>
                        </OColumn>
                    </ODataGrid>
                </div>
                <div class="modal-footer border-top-0 pt-0">
                    <button class="btn btn-primary" @click="apply" :disabled="isWorking">
                        <span v-if="isWorking" class="spinner-border spinner-border-sm me-1"></span>{{ $t("Apply") }}
                    </button>
                </div>
            </div>
        </div>
    </OModal>

</template>

<script setup>
    import { ref, onMounted, watch } from 'vue';
    import { getOrCreateProcedure } from 'o365-modules'
    import { OOrgUnitsLookup } from 'o365-system-lookups';

    import { getOrCreateDataObject } from 'o365-dataobject'

    const props = defineProps({
        dataObject: Object,
        applyProcName: {
            type: String, 
            default: 'astp_Arena_PublishedToBatch'
        },
        recordType: {
            type: String, 
            default: 'OrgUnit' // could be based on some hardcoded view to be used in applyProc+fetching recordType
        },
        modalTitle: String, /*slot instead?*/
        insertOnly: {
            type: Boolean, 
            default: false
        },
        doNotShowDoNotInherit: {
            type: Boolean, 
            default: false
        },
        viewName: {
            type: String, 
            required: true
        },
        customOptions: {
            type: Object, 
            default: null
        },
    })
    const emit = defineEmits(['applied']);

    const isWorking = ref(false);
    const arenaBatchDialog = ref(null);
    const updateOptions = ref("Insert");
    const replace_ID = ref(null);
    const replaceName = ref(null);
    const batchSetupRef = ref(null);

    const setWorking = (bool) => {
        isWorking.value = bool;
    }

    const dsBatchSetup = getOrCreateDataObject({
        id: 'dsBatchSetup',
        viewName: 'atbv_Arena_BatchConfig',
        maxRecords: 50,
        allowInsert:true,
        allowUpdate:true,
        allowDelete:true,
        fields: [
            { name: "PrimKey", type: "string" },
            { name: "General_ID", type: "number" },
            { name: "ConfigJson", type: "string" },
            { name: "Name", type: "string" }
        ]
    });

    dsBatchSetup.fields.ConfigJson.jsonAlias = "JsonField";

    dsBatchSetup.recordSource.whereClause = props.viewName ?  `Name = '${props.viewName}'` : '';
    dsBatchSetup.load().then();

    dsBatchSetup.on('BeforeCreate', (options) => {
        if (props.viewName){
            options.values["Name"] = props.viewName;
        }
    });

    const applyProc = new getOrCreateProcedure({ id: "applyProc", procedureName: props.applyProcName == "astp_Arena_PublishedToBatch" && props.customOptions ? "astp_Arena_PublishedToBatchCustomOptions" : props.applyProcName, timeout: 60 });
    const apply = async () => {
        setWorking(true);
        const selectedRows = await props.dataObject.selectionControl.getSelectedRows({fields:[{name:"ID"}]});

        let input = {Ids: selectedRows.map(r => [r.ID]), ViewName: props.viewName, Operation: (props.insertOnly ? 'InsertOnly' : updateOptions.value), Replace_ID: replace_ID.value};
        if (props.customOptions){
            input.CustomOptions = JSON.stringify(props.customOptions);
        }

        applyProc.execute(input).then(()=>{
            replace_ID.value = null;
            replaceName.value = null;

            setWorking(false);
            arenaBatchDialog.value.hide();
            props.dataObject.load();
            dsBatchSetup.load();
            emit('applied');
        }).catch(()=>{setWorking(false);});
    } 

</script>